/**
 * BE STUDIO
 * BLIND TIGER
 * Web Application
 *
 * Styles
 *
 * Author: E. Lew, B. Mockute
 * Copyright: (C)2022, BE Studio
 * See: www.its-be-studio.com
 */
/**
 * GENERAL
 */
/* VARIABLES */
/* Dimensions */
/* Colours */
/* Typography */
/* STYLES */
/* Page */
html {
  overflow-x: hidden !important;
}

:root {
  --min-desktop-width: 0 !important;
}

#root {
  width: 100% !important;
}

.mag {
  min-width: initial;
}

.page {
  width: 100%;
}

.mag .mag-pages-container .container .page,
.mag .mag-pages-container .container {
  width: 100% !important;
}

.products-page,
.content-bounds,
[data-id="634939a4ea987f00275ce10b"] {
  width: 100% !important;
}

.products-page {
  transform: scale(1) !important;
}

.page-content-container {
  height: 100% !important;
}

/* Buttons */
.common-button .text {
  width: 100% !important;
}

/**
 * LOGO
 */
[data-id="634031097bf864002ede00e2"] {
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  animation: rainbow 10s infinite;
}

/* ANIMATION */
@keyframes rainbow {
  0% {
    box-shadow: 0 0 20px 5px #3c3cd8;
  }
  14% {
    box-shadow: 0 0 20px 5px red;
  }
  28% {
    box-shadow: 0 0 20px 5px orange;
  }
  42% {
    box-shadow: 0 0 20px 5px yellow;
  }
  56% {
    box-shadow: 0 0 20px 5px green;
  }
  70% {
    box-shadow: 0 0 20px 5px blue;
  }
  84% {
    box-shadow: 0 0 20px 5px #4b0082;
  }
  100% {
    box-shadow: 0 0 20px 5px #3c3cd8;
  }
}
/**
 * CART BUTTON
 */
[data-id="634054b030f6f30013a049f0"].common-button .text {
  display: block !important;
}

/**
 * MENU BUTTON
 */
[data-id="63403bb37bf864002edf5d88"] {
  cursor: pointer;
}
[data-id="63403bb37bf864002edf5d88"]:hover {
  opacity: 0.75;
}

/**
 * MENU
 */
[data-id="6342ed8272f6fa001c35b9b5"] {
  background-color: rgba(0, 0, 0, 0.4) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

[data-id="6367d05a48ff6e0037db11ac"],
[data-id="6342ee5c6e5e290020b0593d"],
[data-id="6342ee691954b4003db63c38"],
[data-id="6342ee7b2e454a002276fbe3"],
[data-id="6342eed0603285003d5b76a6"],
[data-id="6342f0fd4b853e001976c83c"],
[data-id="6367c8a8ac9a4e7adb2f10c2"],
[data-id="6367c93e0b3d49002423529a"],
[data-id="6342ed8272f6fa001c35b9b5"] {
  display: none;
}

/**
 * SKINCARE PAGE
 */
.skincare-product {
  position: relative;
  width: 100%;
  text-align: center;
}
.skincare-product img {
  width: 100%;
}
.skincare-product img:hover {
  opacity: 0.9;
}

.skincare-link-container {
  width: 100%;
  text-align: center;
}

.skincare-link {
  font: 20px "custom_69560", "Times New Roman", serif;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.1em;
}
.skincare-link.link-red {
  color: red;
}
.skincare-link:hover {
  opacity: 0.5;
}
@media (max-width: 640px) {
  .skincare-link {
    font-size: 13px;
    line-height: 0.4em;
  }
}

/**
 * JEWELLERY PRODUCTS PAGE
 */
.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin-bottom: 500px;
  padding-bottom: 20px;
}
@media (max-width: 640px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}

[data-id="634939a4ea987f00275ce10b"] {
  width: 100% !important;
  height: 100% !important;
}

.products-widget {
  width: 100%;
}

.products-widget-image {
  width: 100%;
  height: 50vw;
  max-height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 640px) {
  .products-widget-image {
    height: 90vw;
    max-height: 500px;
  }
}

.products-widget-title {
  font: 15px "custom_69560", "Times New Roman", serif;
  line-height: 0.9em;
  text-transform: uppercase;
  padding: 10px 0 5px 5px;
}
@media (max-width: 640px) {
  .products-widget-title {
    font-size: 13px;
    line-height: 1.1em;
    padding: 5px 0 5px 5px;
  }
}

.products-widget-price {
  font: 11px "vcsm", "Helvetica", "Arial", sans-serif;
  padding: 5px 0 50px 5px;
}
@media (max-width: 640px) {
  .products-widget-price {
    padding: 2px 0 30px 5px;
  }
}
.products-widget-price a:hover {
  opacity: 0.5;
}
.products-widget-price svg {
  width: 20px;
}
@media (max-width: 640px) {
  .products-widget-price svg {
    width: 30px;
  }
}

/**
 * SINGLE PRODUCT PAGE
 */
/* CONTAINER */
.mag .mag-pages-container .container .page .content-scroll-wrapper .content-bounds {
  height: auto !important;
  min-height: 100vh !important;
  overflow: auto !important;
}

/* IMAGE */
.counters-container {
  display: none;
}

.next-picture-arrow-middle {
  display: block !important;
}

.widget-slideshow {
  top: 0 !important;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 640px) {
  .widget-slideshow {
    height: 100vh !important;
  }
}
@media (min-width: 640px) {
  .widget-slideshow .images {
    height: 100vh !important;
  }
}
.widget-slideshow .image {
  background-position: center !important;
  background-size: cover !important;
  height: 100vh !important;
}
@media (max-width: 640px) {
  .widget-slideshow .image {
    height: inherit !important;
  }
}

/* TITLE */
.product-title {
  font: 400 35px "custom_69560", "Times New Roman", serif;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 640px) {
  .product-title {
    font-size: 22px;
    line-height: 18px;
  }
}

/* PRICE */
.product-price {
  font: 400 12px "vcsm", "Helvetica", "Arial", sans-serif;
  text-align: center;
}

/* ADD TO CART COMPONENT */
.select-dropdown-triangle {
  width: 4px !important;
  height: auto !important;
}

.attributes-option {
  font-family: "custom_69560", "Times New Roman", serif !important;
}

.add-to-cart-error-block {
  border-color: red !important;
}

.attributes-option,
.add-to-cart-button {
  text-transform: lowercase !important;
}

/* DETAIL */
.product-detail-block {
  margin: 12px 0;
}
.product-detail-block:last-child {
  padding-bottom: 100px;
}

.product-detail-title {
  font: 600 6px "vcsm", "Helvetica", "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}

.product-size,
.product-scent-notes,
.product-ingredients,
.product-materials,
.product-circ-length,
.product-bead-size,
.product-description p {
  font: 8px "custom_69560", "Times New Roman", serif;
  line-height: 12px;
  margin: 4px 0;
}
.product-size em,
.product-scent-notes em,
.product-ingredients em,
.product-materials em,
.product-circ-length em,
.product-bead-size em,
.product-description p em {
  font-family: "custom_69755", "Times New Roman", serif;
}
@media (max-width: 640px) {
  .product-size,
  .product-scent-notes,
  .product-ingredients,
  .product-materials,
  .product-circ-length,
  .product-bead-size,
  .product-description p {
    font-size: 11px;
    line-height: 17px;
  }
}

@media (max-width: 640px) {
  .product-description p {
    margin: 0.8em 0;
  }
}
.product-description a {
  color: black;
}
.product-description a:hover {
  opacity: 0.5;
}

/**
 * COOKIE DECLARATION
 */
.CookieDeclaration {
  height: 640px;
  font: 10px "custom_69560", "Times New Roman", serif !important;
  overflow-y: scroll;
}
@media (max-width: 640px) {
  .CookieDeclaration {
    height: 560px;
  }
}
.CookieDeclaration a {
  color: black !important;
}
.CookieDeclaration a:hover {
  opacity: 0.5;
}

.CookieDeclarationType {
  width: 97%;
}

/**
 * CART
 */
.cart-item-title,
.cart-item-attributes,
.cart-item-price,
.cart-item-price input,
.cart-price-input,
.cart-item-price-value,
.cart-item-quantity,
.cart-item-quantity input {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.cart-item-title {
  font-size: 14px;
}

.cart-item-attributes,
.cart-item-price,
.cart-item-price input,
.cart-price-input,
.cart-item-price-value,
.cart-item-quantity,
.cart-item-quantity input {
  font-size: 12px !important;
}

.cart-item-block:hover,
#cart-sidebar.cart-is-mobile .sidebar-body .cart-item-block {
  background-color: #f3f2ee !important;
}

.cart-item-remove-btn,
.cart-item-edit-group {
  visibility: visible !important;
}

.cart-sidebar .sidebar-btn {
  height: 28px !important;
  background-color: #f3f2ee !important;
  border: 0 !important;
  filter: drop-shadow(rgba(59, 59, 59, 0.18) 0 2px 3px) !important;
}
.cart-sidebar .sidebar-btn input {
  font: 1.5em "custom_69755", "Times New Roman", serif !important;
}
.cart-sidebar .sidebar-btn svg {
  display: none;
}

#cart-sidebar .sidebar-title input {
  font-family: "custom_69560", "Times New Roman", serif;
  font-weight: normal;
  text-transform: uppercase !important;
}

.empty-cart-title-input {
  font: 400 24px "custom_69560", "Times New Roman", serif !important;
  text-transform: uppercase !important;
}

.close-button {
  right: 20px !important;
  left: auto !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(10px);
}

/**
 * FOOTER
 */
[data-id="636b91ff30cc44003723b193"] {
  pointer-events: none;
  overflow: hidden;
}